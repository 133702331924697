import "./index.css";

// import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import Start from "./Start";

// render application
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>

    <Start />
    {/* <App /> */}

  </React.StrictMode>
);
