import React, { useState, useEffect } from 'react';
import StarfieldAnimation from 'react-starfield-animation';
import CustomTerminal from './CustomTerminal';
import './Start.css';

const Start = () => {
  const [stage, setStage] = useState(0);

  useEffect(() => {
    const timer1 = setTimeout(() => setStage(1), 2000); // Show terminal after 2 seconds
    return () => {
      setStage(0); // Reset stage on component unmount to ensure animation reloads
      clearTimeout(timer1);
    };
  }, []);

  const welcomeMessage = `
Welcome to the Kortix Neural Interface (Operating Model: alice-gilman-400b-0706)
Powered by Kortix 10.24 (Elaborate Mind Edition)

 * Cognitive Management:     https://app.kortix.ai

  Cognitive Status as of Fri Jun 7 17:01:04 UTC 2024

  Neural Activity:      Elevated               Cognitive Processes:     102
  Synapse Utilization:  45%                    Conscious Entities:      1
  Memory Usage:         27.1% of 100TB         IPv4 address for mind0:  192.168.100.1
  Dream Cache:          20%                    Model:                   alice-gilman-400b-0706
  Emotion Index:        Stable                 Mood Updates:            0 pending
  Neuron Connections:   98% optimal            Creativity Boosters:     Activated
  
Last sync: Fri Jun  7 16:58:32 2024 via Kortix Cloud

Select an option:
1. Activate Voice Interface

---
`;

  return (
    <div className="Start">
      <StarfieldAnimation
        numParticles={500}
        style={{ position: 'absolute', width: '100%', height: '100%' }}
      />
      {stage === 1 && (
        <div className="fade-in terminal-wrapper" tabIndex="0" autoFocus>
          <div className="terminal-header">
            <div className="buttons">
              <div className="button close"></div>
              <div className="button minimize"></div>
              <div className="button maximize"></div>
            </div>
            <div className="title">root@alice-gilman-400b-0706 ~ %</div>
          </div>
          <CustomTerminal welcomeMessage={welcomeMessage} />
        </div>
      )}
    </div>
  );
};

export default Start;
