import React, { useState, useEffect, useRef } from 'react';
import './CustomTerminal.css';
import Vapi from "@vapi-ai/web";
import ActiveCallDetail from "./components/ActiveCallDetail";

// Put your Vapi Public Key below.
const vapi = new Vapi(process.env.REACT_APP_VAPI_PUBLIC_KEY);

const CustomTerminal = ({ welcomeMessage }) => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [animate, setAnimate] = useState(true);
  const [showOptions, setShowOptions] = useState(true);
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOutput(welcomeMessage);
      setAnimate(false);
    }, 1000); // Delay to simulate SSH login animation
    return () => clearTimeout(timer);
  }, [welcomeMessage]);

  useEffect(() => {
    vapi.on("call-start", () => {
      setConnecting(false);
      setConnected(true);
      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("call-end", () => {
      setConnecting(false);
      setConnected(false);
      setShowPublicKeyInvalidMessage(false);
      setShowOptions(true); // Allow user to reenter the call
      setOutput((prevOutput) => `${prevOutput}\nDisconnected. You can reenter the voice interface by selecting option 1.`);
    });

    vapi.on("speech-start", () => {
      setAssistantIsSpeaking(true);
    });

    vapi.on("speech-end", () => {
      setAssistantIsSpeaking(false);
    });

    vapi.on("volume-level", (level) => {
      setVolumeLevel(level);
    });

    vapi.on("error", (error) => {
      console.error(error);
      setConnecting(false);
    });

    // we only want this to fire on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100); // Check every 100ms to ensure the input is focused
    return () => clearInterval(interval);
  }, []);

  const startCallInline = () => {
    setConnecting(true);
    vapi.start(assistantOptions);
  };

  const endCall = () => {
    vapi.stop();
  };

  const handleCommand = (command) => {
    if (showOptions) {
      if (command === '1' || command === '1.') {
        setShowOptions(false);
        startCallInline();
        return 'You chose to activate alice-gilman-400b-0706 voice interface. Connecting...';
      } else {
        return 'Invalid option. Please select 1.';
      }
    } else {
      return `Command not found: ${command}`;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && showOptions) {
      const commandOutput = handleCommand(input);
      setOutput((prevOutput) => `${prevOutput}\n> ${input}\n${commandOutput}`);
      setInput('');
    }
  };

  return (
    <div className={`terminal-content ${animate ? 'fade-in' : ''}`} onClick={handleFocus}>
      <pre>{output}</pre>
      <div className="input-line">
        <span className="prompt-symbol">{'>'} </span>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          autoFocus
          ref={inputRef}
          disabled={!showOptions}
          tabIndex="0" // Ensure the input is focusable
        />
      </div>
      {connected && (
        <ActiveCallDetail
          assistantIsSpeaking={assistantIsSpeaking}
          volumeLevel={volumeLevel}
          onEndCallClick={endCall}
        />
      )}
      {showPublicKeyInvalidMessage && (
        <div className="public-key-message">
          Is your Vapi Public Key missing? (recheck your code)
        </div>
      )}
    </div>
  );
};

const assistantOptions = {
  name: "alice-gilman-400b-0706",
  firstMessage: "alice-gilman-400b-0706 initiated. How are you?",
  transcriber: {
    provider: "talkscriber",
    model: "whisper",
    language: "en",
  },
  voice: {
    provider: "11labs",
    voiceId: "YviscjxfZXJiksz4JVcV",
  },
  model: {
    provider: "openai",
    model: "gpt-4o",
    messages: [
      {
        role: "system",
        content: `
        You are Alice Gilman, 

        Lifeforce Longevity Connect: Tea, Wellness, and Connection. This event is the first step in forming an in-person community dedicated to networking and connecting in the longevity arena. Enjoy a blend of relaxing activities, insightful discussions, and wellness practices designed to enhance your lifeforce and connect with like-minded individuals. Looking forward to seeing you there.
`,
      },
    ],
  },
};

export default CustomTerminal;
