const ActiveCallDetail = ({ assistantIsSpeaking, volumeLevel, onEndCallClick }) => {
  return (
    <div style={{ fontFamily: "monospace", color: "#00FF00", backgroundColor: "#000", padding: "10px", borderRadius: "5px" }}>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <div
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: assistantIsSpeaking ? "#3ef07c" : "#f03e3e",
            marginRight: "10px",
            borderRadius: "4px",
          }}
        />
        <p style={{ color: "#00FF00", margin: 0 }}>
          {assistantIsSpeaking ? "alice-gilman-400b-0706 active" : "alice-gilman-400b-0706 idle"}
        </p>
      </div>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <p style={{ color: "#00FF00", margin: 0 }}>Volume Level:</p>
        <div style={{ display: "flex", marginLeft: "10px" }}>
          {Array.from({ length: 10 }, (_, i) => (
            <div
              key={i}
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: i < volumeLevel ? "#3ef07c" : "#555",
                marginRight: "2px",
              }}
            />
          ))}
        </div>
      </div>
      <div style={{ marginTop: "10px", textAlign: "left" }}>
        {/* <button
          onClick={onEndCallClick}
          style={{
            fontFamily: "monospace",
            color: "#000",
            backgroundColor: "#00FF00",
            border: "none",
            padding: "5px 10px",
            cursor: "pointer",
          }}
        >
          End Call
        </button> */}
      </div>
    </div>
  );
};

export default ActiveCallDetail;